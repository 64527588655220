<template>
  <div class="container shopping-cart">
    <h1>{{ $t('shoppingCart.title') }}</h1>

    <div v-if="showNoCartYet">
      <p>{{ $t('shoppingCart.noCartYet') }}</p>
    </div>

    <div v-if="!showNoCartYet">
      <table width="100%">
        <thead>
          <tr>
            <th>{{ $t('shoppingCart.product') }}</th>
            <th>{{ $t('shoppingCart.quantity') }}</th>
            <th>{{ $t('shoppingCart.customerPrice') }}</th>
            <th>{{ $t('shoppingCart.lineItemPrice') }}</th>
            <th>{{ $t('shoppingCart.actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in cart.items" :key="item.productId">
            <td><img class="product" :src="`/img/shop/${item.productId}.jpg`" /></td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.customerPriceInCents / 100 }} €</td>
            <td>{{ (item.quantity * item.customerPriceInCents / 100) }} €</td>
            <td @click.stop="removeFromCart(item)">
              <mdicon name="cart-remove" />
            </td>
          </tr>
          <tr class="cart-summary cart-summary-start">
            <td class="title">{{ $t('shoppingCart.totalPrice') }}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>{{ calculateTotal }} €</td>
            <td>&nbsp;</td>
          </tr>
          <tr class="cart-summary">
            <td class="title">{{ $t('shoppingCart.shippingCost') }}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <table class="shipping-selection">
                <tr>
                  <td><input disabled type="radio" v-model="shippingCost" :value="0" /></td>
                  <td>{{ $t('shoppingCart.freeShipping') }}</td>
                </tr>
                <tr>
                  <td><input :disabled="freeShipping" type="radio" v-model="shippingCost" :value="10" /></td>
                  <td>{{ $t('shoppingCart.regularShipping') }}</td>
                </tr>
                <tr>
                  <td><input :disabled="freeShipping" type="radio" v-model="shippingCost" :value="35" /></td>
                  <td>{{ $t('shoppingCart.fastShipping') }}</td>
                </tr>
              </table>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr class="cart-summary">
            <td class="title">{{ $t('shoppingCart.purchasePrice') }}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td class="total-price">{{ calculatePurchasePrice }} €</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>

      <div class="usda-restriction">
        Are you ordering from USA? USDA regulations prevent us from shipping meat products into the U.S.
      </div>

      <div>
        <button v-if="!showPaypalButtons" class="purchase" @click="performPurchase">{{
          $t("shoppingCart.buttonPurchase")
        }}</button>
        <div v-if="showPaypalButtons" id="paypalButtons" />
      </div>

      <div v-if="showSuccess" class="mt-8">
        {{ $t("shoppingCart.purchaseThankYou") }}

        <div class="font-mono my-2">
          <div>{{ purchaseRecord.customer.shipping_details.full_name }}</div>
          <div>{{ purchaseRecord.customer.shipping_details.address.address_line_1 }}</div>
          <div>{{ purchaseRecord.customer.shipping_details.address.address_line_2 }}</div>
          <div>
            {{ purchaseRecord.customer.shipping_details.address.postal_code }} {{
              purchaseRecord.customer.shipping_details.address.admin_area_1
            }}, {{ purchaseRecord.customer.shipping_details.address.admin_area_2 }}
          </div>
          <div>{{ purchaseRecord.customer.shipping_details.address.country_code }}</div>
        </div>

        {{ $t("shoppingCart.checkEmail") }} <span class="font-mono whitespace-nowrap">{{
          purchaseRecord.customer.email
        }}</span>.
      </div>
    </div>
  </div>
</template>

<script>
import { axiosInstance } from '@/library'
import { loadScript } from "@paypal/paypal-js"

export default {
  name: "ShoppingCart",
  components: {
  },

  data: () => ({
    cartId: undefined,
    cart: {},
    shippingCostSelected: 10,
    showPaypalButtons: false,
    showSuccess: false,
    showNoCartYet: false
  }),

  beforeMount() {
    this.getShoppingCart()
  },

  computed: {
    calculateTotal() {
      let total = 0

      if (this.cart.items) {
        this.cart.items.forEach((item) => {
          total += item.customerPriceInCents * item.quantity
        })

        return total / 100
      } else
        return 0
    },

    shippingCost: {
      get() {
        return this.shippingCostSelected
      },

      set(newValue) {
        this.shippingCostSelected = newValue
      }
    },

    calculatePurchasePrice() {
      return this.shippingCost + this.calculateTotal
    }
  },

  methods: {
    getShoppingCart() {
      const shoppingCartLocation = this.$cookies.get('shoppingCartLocation')
      console.log('shoppingCartLocation', shoppingCartLocation)

      if (shoppingCartLocation === null) {
        this.showNoCartYet = true
      } else {
        axiosInstance.get(`${window.VUE_APP_GRANJA}${shoppingCartLocation}`)
          .then(response => {
            console.log(response)
            this.cart = response.data

            if (this.calculateTotal >= 20) { // TODO this should come from granja.eu
              this.shippingCostSelected = 0
              this.freeShipping = true
            }
            else {
              this.shippingCostSelected = 10
              this.freeShipping = false
            }
          })
      }
    },

    removeFromCart(item) {
      const shoppingCartLocation = this.$cookies.get('shoppingCartLocation')
      axiosInstance.delete(`${window.VUE_APP_GRANJA}${shoppingCartLocation}/${item.productId}`)
        .then(() => this.getShoppingCart())
    },

    afterPurchase(purchaseRecord) {
      purchaseRecord.products = []
      this.cart.items.forEach((item) => {
        purchaseRecord.products.push(
          {
            productId: item.productId,
            quantity: item.quantity,
            customerPriceInCents: item.customerPriceInCents
          }
        )
      })

      this.purchaseRecord = purchaseRecord

      this.showSuccess = true
      axiosInstance.post(`${window.VUE_APP_GRANJA}/api/sales/sales`, JSON.stringify(purchaseRecord), {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => {
          console.log(response)

          const shoppingCartLocation = this.$cookies.get('shoppingCartLocation')
          axiosInstance.delete(`${window.VUE_APP_GRANJA}${shoppingCartLocation}`)
            .then(() => {
              this.$cookies.remove('shoppingCartLocation')
            })
        })
    },

    performPurchase() {
      this.showPurchaseOptions = false
      this.showPaypal = true
      const afterPurchaseFunction = this.afterPurchase
      const checkoutPrice = this.calculatePurchasePrice.toString()

      this.showPaypalButtons = true
      loadScript({
        "client-id": window.VUE_APP_PAYPAL_CLIENT_ID,
        "currency": "EUR"
      })
        .then((paypal) => {
          paypal
            .Buttons({
              createOrder: (data, actions) => {
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: checkoutPrice
                    }
                  }]
                })
              },
              onApprove: (data, actions) => {
                return actions.order.capture().then(function (orderData) {
                  console.log('orderData ', orderData)
                  const purchaseRecord = {
                    payment: {
                      amountInCents: orderData.purchase_units[0].payments.captures[0].amount.value * 100,
                      transactionId: orderData.purchase_units[0].payments.captures[0].id,
                      paymentProvider: 'PAYPAL',
                      timestamp: orderData.purchase_units[0].payments.captures[0].update_time
                    },
                    customer: {
                      email: orderData.payer.email_address,
                      phone: orderData.payer.phone != null ? JSON.stringify(orderData.payer.phone) : '',
                      shipping_details: {
                        full_name: orderData.purchase_units[0].shipping.name.full_name,
                        address: {
                          address_line_1: orderData.purchase_units[0].shipping.address.address_line_1,
                          address_line_2: orderData.purchase_units[0].shipping.address.address_line_2,
                          admin_area_1: orderData.purchase_units[0].shipping.address.admin_area_1,
                          admin_area_2: orderData.purchase_units[0].shipping.address.admin_area_2,
                          postal_code: orderData.purchase_units[0].shipping.address.postal_code,
                          country_code: orderData.purchase_units[0].shipping.address.country_code
                        }
                      }
                    }
                  }
                  afterPurchaseFunction(purchaseRecord)
                })
              }
            })
            .render("#paypalButtons")
            .catch((error) => {
              console.error("failed to render the PayPal Buttons", error);
            });
        })
        .catch((error) => {
          console.error("failed to load the PayPal JS SDK script", error);
        });
    },
  }
}
</script>

<style scoped>
DIV.shopping-cart {
  width: 80%;
}

IMG.product {
  width: 150px;
}

TABLE {
  border-collapse: collapse;
}

TABLE TH,
TABLE TD {
  text-align: left;
}

.cart-summary {
  background-color: rgb(251, 241, 227);
}

.cart-summary TD.title {
  font-weight: bold;
  padding-left: 0.5em;
}

.cart-summary TD.total-price {
  font-weight: bold;
}

.cart-summary TD {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.cart-summary-start TD {
  padding-top: 1em;
}

TABLE.shipping-selection TD {
  padding-bottom: 0.5em;
  padding-top: unset;
}
</style>
